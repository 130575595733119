import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import parse from "date-fns/parse"
import { format } from "date-fns"
import compareAsc from "date-fns/compareAsc"
import "../styles/article/articles.sass"

export const Article = ({ title, date, desc, path, coverImg, tags }) => (
  <div className="col-12 pb-4 mt-3">
    <Link to={path} className="no-underline" id="path">
      <div className="grow col-12 px-0 mx-auto row">
        <div className="col-12 col-md-4 m-0 p-0">
          <Img
            fluid={coverImg.childImageSharp.fluid}
            objectFit="cover"
            className="article-image"
            style={{ width: "100%", height: "100%", maxHeight: 220 }}
          />
        </div>
        <div className="col-12 col-md-8 my-2">
          <h3 className="mb-1 mt-0"><b>{title}</b></h3>
          <h6>{format(new Date(date), "iii, dd MMM yyyy")}</h6>
          <div className="project-tag-container mb-1">
            {tags.map((tag) => (
              <div key={title+"-"+tag} className="project-tag text-nowrap my-1 mr-2">
                <p className="mb-0" >
                  {tag}
                </p>
              </div>
            ))}
          </div>
          <p className="pb-0 my-0">{desc}</p>
        </div>
      </div>
    </Link>
  </div>
)

export default ({ data }) => {
  let { edges } = data.allMdx // data.markdownRemark holds our post data

  return (
    <div style={{backgroundColor: "#f8f9fa"}}>
      <Layout>
        <SEO title="Articles" />
        <h2>Articles</h2>
        <div className="px-3 px-lg-0">
          <div className="container-small row">
            {edges
              .sort((a, b) => {
                var resultA = parse(
                  a.node.frontmatter.startDate,
                  "yyyy-MM-dd",
                  new Date()
                )
                var resultB = parse(
                  b.node.frontmatter.startDate,
                  "yyyy-MM-dd",
                  new Date()
                )
                return compareAsc(resultB, resultA)
              })
              .map((item) => (
                <Article
                  {...item.node.frontmatter}
                  key={item.node.frontmatter.title}
                />
              ))}
            </div>
          </div>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query Articles {
    allMdx(filter: {frontmatter: {public: {eq: true}, type: {eq: "Article"}}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            type
            title
            desc
            tags
            date
            path
            coverImg {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`